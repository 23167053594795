.container-job-index {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  width: 100%; /* Ensure the container takes the full width */
  min-height: calc(100vh - 411px); /* Adjust height to fill the screen */
  box-sizing: border-box; /* Include padding in the height calculation */
  overflow-y: auto;
}
